/*  predefined variables  */
/* common container for all containers and components */
.container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.table_container {
  width: 100%;
}

.table_container .table {
  background-color: white;
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

.table_container .table .tableHd {
  padding: 1rem 1.5rem;
  font-weight: 500;
  background-color: #f2f3f4;
  font-family: inherit;
  font-size: 1.4rem;
  color: black;
  border: 1px solid rgba(98, 105, 118, 0.16);
  text-align: left;
}

.table_container .table td.tableActionTd,
.table_container .table td.tableStatusTd {
  border: 1px solid rgba(98, 105, 118, 0.16);
}

.table_container .table td.tableActionTd .tableAction,
.table_container .table td.tableStatusTd .tableAction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  background: transparent;
}

.table_container .table td.tableActionTd .tableAction .btn,
.table_container .table td.tableStatusTd .tableAction .btn {
  border: #cfcaca thin solid;
  background-color: #efeded;
  color: black;
  padding: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.table_container .table td.tableActionTd .tableAction .btn svg,
.table_container .table td.tableStatusTd .tableAction .btn svg {
  stroke: #5a5a5a;
}

.table_container .table td.tableActionTd .tableAction .btn:hover,
.table_container .table td.tableStatusTd .tableAction .btn:hover {
  background-color: #206bc4;
  border-color: #206bc4;
}

.table_container .table td.tableActionTd .tableAction .btn:hover svg,
.table_container .table td.tableStatusTd .tableAction .btn:hover svg {
  stroke: white;
}

.table_container .table td.tableStatusTd {
  text-align: center;
  cursor: pointer;
}

.table_container .table td.tableStatusTd svg {
  stroke-width: 0.4rem;
}

.table_container .table td.tableStatusTd svg.icon_green {
  color: green;
}

.table_container .table td.tableStatusTd svg.icon_red {
  color: red;
}

.table_container .table .tableTd {
  padding: 1.1rem 1.5rem;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 9999px transparent;
          box-shadow: inset 0 0 0 9999px transparent;
  border: 1px solid rgba(98, 105, 118, 0.16);
  font-size: 1.3rem;
}

.table_container .table tbody > tr:nth-child(even) > td {
  background-color: #f2f3f4;
}

.table_container .table_footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1.5rem 2rem;
}

.table_container .table_footer .pagination ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.table_container .table_footer .pagination ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.table_container .table_footer .pagination ul li span {
  font-size: 1.4rem;
}

.table_container .table_footer .pagination ul li:first-child,
.table_container .table_footer .pagination ul li:last-child {
  font-weight: 550;
  cursor: pointer;
}

.table_container .table_footer .pagination ul li:first-child:hover,
.table_container .table_footer .pagination ul li:last-child:hover {
  color: #206bc4;
}

.table_container .table_footer .pagination ul li.disabled {
  color: #afafaf;
  cursor: default;
}

.table_container .table_footer .pagination ul li.disabled:hover {
  color: #afafaf;
  cursor: default;
}

.table_container .table_footer .footer_infos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.table_container .table_footer .footer_infos .show_rows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.table_container .table_footer .footer_infos .show_rows span {
  font-size: 1.3rem;
}

.table_container .table_footer .footer_infos .show_rows select {
  font-size: 1.3rem;
  border: #858585 thin solid;
  border-radius: 0.3rem;
}

.table_container .table_footer .footer_infos .show_rows select:focus {
  outline: none;
  border-color: #b5b5b5;
}
