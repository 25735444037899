.form_wrapper {
  position: fixed;
  overflow-y: scroll;
  padding-bottom: 10rem;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form_wrapper .form_box {
  margin: auto;
  margin-top: 3rem;
  width: 60rem;
  padding: 2rem;
  background-color: white;
  -webkit-box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.4rem;
}

.form_wrapper .form_box .form_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.5rem;
}

.form_wrapper .form_box .form_header h2.form_title {
  font-size: 1.4rem;
  font-weight: 600;
}

.form_wrapper .form_box .form_header svg {
  top: 0;
  right: 0;
  stroke: #bfbfbf;
  cursor: pointer;
}

.form_wrapper .form_box .form_header svg:hover {
  stroke: black;
}

.form_wrapper .form_box .error_message {
  color: #fb1d1d;
  font-size: 1.3rem;
}

.form_wrapper .form_box .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 1.5rem;
}

.form_wrapper .form_box .form .input_row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.form_wrapper .form_box .form .input_row .form_input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.divider {
  margin-top: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.5rem;
}

.form_wrapper .form_box .form .input_row .form_input label {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}

.form_wrapper .form_box .form .input_row .form_input .input_description {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form_wrapper .form_box .form .input_row .form_input .input_error {
  color: #fb1d1d;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form_wrapper .form_box .form .input_row .form_input input,
.form_wrapper .form_box .form .input_row .form_input select,
.form_wrapper .form_box .form .input_row .form_input textarea {
  font-size: 1.4rem;
  color: black;
  border: #bfbfbf thin solid;
  padding: 0.5rem 0.5rem;
  font-weight: 550;
  border-radius: 0.3rem;
}

.form_wrapper .form_box .form .input_row .form_input input:focus,
.form_wrapper .form_box .form .input_row .form_input select:focus,
.form_wrapper .form_box .form .input_row .form_input textarea:focus {
  outline: none;
  border: #858585 thin solid;
}

.form_wrapper .form_box .form .input_row .form_input input:-moz-read-only {
  cursor: default;
  background-color: #f9f9f9;
}

.form_wrapper .form_box .form .input_row .form_input input:read-only {
  cursor: default;
  background-color: #f9f9f9;
}

.form_wrapper .form_box .form .input_row .form_input_flex {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form_wrapper .form_box .form .input_row .form_input_flex label {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}

.form_wrapper .form_box .form .input_row .form_input_flex .input_description {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form_wrapper .form_box .form .input_row .form_input_flex .input_error {
  color: #fb1d1d;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form_wrapper .form_box .form .input_row .form_input_flex input,
.form_wrapper .form_box .form .input_row .form_input_flex select {
  font-size: 1.4rem;
  color: black;
  border: #bfbfbf thin solid;
  padding: 0.5rem 0.5rem;
  font-weight: 550;
  border-radius: 0.3rem;
}

.form_wrapper .form_box .form .input_row .form_input_flex input:focus,
.form_wrapper .form_box .form .input_row .form_input_flex select:focus {
  outline: none;
  border: #858585 thin solid;
}

.form_wrapper .form_box .form .input_row .form_checkbox {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin-top: 2.5rem;
}

.form_wrapper .form_box .form .input_row input[type='color'] {
  width: 100% !important;
  padding: 0;
}

.form_wrapper .form_box .form .readonly_group {
  /* padding-top: 1.5rem; */
  /* margin-top: 1.5rem; */
  width: 100%;
  /* border-top: #e7e7e7 thin solid; */
}

.form_wrapper .form_box .form .readonly_group input:-moz-read-only {
  background-color: #f5f5f5;
  border-color: #f2f2f2;
}

.form_wrapper .form_box .form .readonly_group input:read-only {
  background-color: #f5f5f5;
  border-color: #f2f2f2;
}

.form_wrapper .form_box .form .readonly_group input:focus {
  outline: none;
  border: #f2f2f2 thin solid !important;
}

.form_wrapper .form_box .form_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2rem;
}

.form_wrapper .form_box .form_button button {
  padding: 0.8rem 5rem;
  font-size: 1.4rem;
  font-weight: 550;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 0.4rem;
}

.form_wrapper .form_box .form_button button:hover {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
}

.form_wrapper .combo_form_box {
  width: 80rem;
  top: 80px;
}

.form_wrapper .combo_form_box ._multiselect input {
  border: none !important;
}

.form_wrapper .combo_form_box ._multiselect input::-webkit-input-placeholder {
  font-weight: 400 !important;
}

.form_wrapper .combo_form_box ._multiselect input:-ms-input-placeholder {
  font-weight: 400 !important;
}

.form_wrapper .combo_form_box ._multiselect input::-ms-input-placeholder {
  font-weight: 400 !important;
}

.form_wrapper .combo_form_box ._multiselect input::placeholder {
  font-weight: 400 !important;
}

.form_wrapper .combo_form_box .status_combo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-block: 1rem;
}

.form_wrapper .combo_form_box .status_combo button {
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 0.4rem;
}

.form_wrapper .combo_form_box .status_combo button div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.form_wrapper .combo_form_box .status_combo .enabledbtn {
  cursor: pointer;
  border: #ff6c45 thin solid;
  background: #ffeeee;
}

.form_wrapper .combo_form_box .status_combo .enabledbtn svg {
  stroke: #ff6c45;
}

.form_wrapper .combo_form_box .status_combo .enabledbtn span {
  color: #ff6c45;
}

.form_wrapper .combo_form_box .status_combo .enabledbtn:hover {
  background-color: #fddede;
}

.form_wrapper .combo_form_box .status_combo .disabledbtn {
  cursor: pointer;
  border: #009f38 thin solid;
  background: #f3fff5;
}

.form_wrapper .combo_form_box .status_combo .disabledbtn svg {
  stroke: #009f38;
}

.form_wrapper .combo_form_box .status_combo .disabledbtn span {
  color: #009f38;
}

.form_wrapper .combo_form_box .status_combo .disabledbtn:hover {
  background-color: #d6fddc;
}