/*  predefined variables  */
/* common container for all containers and components */
.container, .page_container .container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn, .page_container .container .tableTitle .right_buttons button {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.page_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.page_container .container .tableTitle {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(98, 105, 118, 0.16);
}

.page_container .container .tableTitle .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page_container .container .tableTitle .title h2 {
  font-size: 1.8rem;
  color: #1e293b;
}

.page_container .container .tableTitle .right_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.page_container .container .tableTitle .right_buttons button {
  padding: 0.65rem 1.5rem;
  cursor: pointer;
}

.page_container .container .tableBody {
  margin-block: 2.5rem;
  width: 100%;
  -webkit-box-shadow: rgba(30, 41, 59, 0.04) 0 2px 4px 0;
          box-shadow: rgba(30, 41, 59, 0.04) 0 2px 4px 0;
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-radius: 0.4rem;
  -webkit-transition: opacity 0.3s ease-out, -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
  transition: opacity 0.3s ease-out, -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
}
