/*  predefined variables  */
/* common container for all containers and components */
.container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.page_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container .details_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
  box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-radius: 0.4rem;
  background-color: white;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.container .details_container .details {
  background-color: white;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
  padding: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 1.5rem;
}

.container .details_container .details .customer_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container .details_container .details .customer_details .customer_name {
  font-size: 1.4rem;
}

.container .details_container .details .customer_details .changescore_btn button {
  font-size: 1.4rem;
  background-color: #206bc4;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
}

.container .details_container .details .customer_details .changescore_btn button:hover {
  background-color: #1c589f;
}

.container .details_container .details .chart_container {
  width: 100%;
  height: 30rem;
  margin-block: 1rem;
}

.container .details_container .details .score_container {
  width: 100%;
  margin-block: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-column-gap: 5rem;
          column-gap: 5rem;
}

.container .details_container .details .score_container .digi_score {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .details_container .details .score_container .digi_score h2.notFound {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: #333;
  font-size: 1.4rem;
}

.container .details_container .details .score_container .experian_score {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .details_container .details .score_container .experian_score h2.notFound {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: #333;
  font-size: 1.4rem;
}

.container .details_container .details .score_container .score_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  border: 1px solid rgba(35, 46, 70, 0.08);
}

.container .details_container .details .score_container .score_card .icon svg {
  stroke-width: 0.5;
}

.container .details_container .details .score_container .score_card .desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container .details_container .details .score_container .score_card .desc h3 {
  font-size: 2rem;
}

.container .details_container .details .score_container .score_card .desc h1 {
  font-size: 4rem;
  font-weight: 500;
}

.container .details_container .details .score_container .score_card .desc span {
  font-size: 1.4rem;
}

.container .details_container .details .remarks_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-block: 1.5rem;
}

.container .details_container .details .remarks_container h2 {
  font-size: 1.4rem;
}

.container .details_container .details .remarks_container p {
  font-size: 1.4rem;
  margin-top: 0.5rem;
}

.container .details_container .details .addinfo_container {
  margin-block: 2rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
  gap: 1rem;
}

.container .details_container .details .tenurearpucf_container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.statistics {
  border: 1px solid rgba(35, 46, 70, 0.08);
  padding: 1rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 0.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.statistics span {
  font-size: 1.4rem;
}

.statistics h2 {
  font-size: 1.5rem;
}

.container .details_container .history {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  border-radius: 0.4rem;
}
