/*  predefined variables  */
/* common container for all containers and components */
.container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.header_wrapper {
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
