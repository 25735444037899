/*  predefined variables  */
/* common container for all containers and components */
.container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.header_top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
          box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
}

.header_top .header_top_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.header_top .header_top_container .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-block: 0.8rem;
  align-items: center;
}

.header_top .header_top_container .logo img {
  width: 5rem;
}

.header_top .header_top_container .logo h2 {
  font-size: 2rem;
  font-weight: 800;
  padding-left: 0.8rem;
  color: #545454;
}

.header_top .header_top_container .right_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.header_top .header_top_container .right_menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_top .header_top_container .right_menu ul li.auth_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(98, 105, 118, 0.16);
  height: 100%;
  border-bottom: none;
  border-top: none;
  padding-inline: 1.5rem;
}

.header_top .header_top_container .right_menu ul li.auth_info .auth_info_picture img {
  width: 3.2rem;
  border-radius: 50%;
  border: 1px solid rgba(98, 105, 118, 0.16);
}

.header_top .header_top_container .right_menu ul li.auth_info .user_name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-left: 1rem;
}

.header_top .header_top_container .right_menu ul li.auth_info .user_name h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgba(30, 41, 59, 0.8);
}

.header_top .header_top_container .right_menu ul li.auth_info .user_name h2:hover {
  color: #1e293b;
}

.header_top .header_top_container .right_menu ul li.auth_info .user_name span {
  font-size: 1.1rem;
  color: #1e293b;
}

.header_top .header_top_container .right_menu ul li.auth_info .user_name span:hover {
  color: #1e293b;
}

.header_top .header_top_container .right_menu ul li.logout_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #f5f5f5;
  border: 1px solid rgba(98, 105, 118, 0.16);
  border-left: none;
  border-top: none;
  border-bottom-color: 1px solid rgba(98, 105, 118, 0.16);
  height: 100%;
  padding-inline: 1rem;
  cursor: pointer;
}

.header_top .header_top_container .right_menu ul li.logout_button span {
  font-size: 1.4rem;
  -webkit-margin-start: 0.5rem;
          margin-inline-start: 0.5rem;
  color: #545454;
}

.header_top .header_top_container .right_menu ul li.logout_button:hover span {
  color: #206bc4;
}

.header_top .header_top_container .right_menu ul li.logout_button:hover svg {
  stroke: #206bc4;
}
