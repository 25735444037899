.wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 30%;
      flex-basis: 30%;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
          box-shadow: inset 0 -1px 0 0 rgba(98, 105, 118, 0.16);
  border: 1px solid rgba(98, 105, 118, 0.16);
  background-color: white;
  border-radius: 0.4rem;
  margin-bottom: 2.5rem;
}

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2.5rem;
}

.container .title {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(98, 105, 118, 0.16);
}

.container .title h2 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #1e293b;
}

.container .body {
  font-size: 1.3rem;
  padding-top: 1.5rem;
}

.container .body .final_score {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.container .body .combo_container {
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid rgba(35, 46, 70, 0.08);
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.container .body .combo_container .product_content {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid rgba(35, 46, 70, 0.08);
}

.container .body .combo_container .combo_content {
  margin-top: 0.5rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.combo_content .attribute_container {
  padding: 1rem;
  border: 1px solid rgba(35, 46, 70, 0.08);
}

.attribute_title {
  font-size: 1.5rem;
  font-weight: bold;
}

.attribute_body {
  margin-top: 1rem;
  padding-left: 1rem;
}

.combo_content .attribute_body p {
  margin-top: 0.5rem;
}

