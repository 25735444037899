/*  predefined variables  */
/* common container for all containers and components */
.container {
  width: 132rem;
}

.form_control {
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(36, 36, 36, 0.8);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9dbde;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form_control:focus {
  outline: rgba(224, 232, 252, 0.5) 0.3rem solid;
}

.form_control::-webkit-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control:-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::-ms-input-placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.form_control::placeholder {
  font-size: 1.4rem;
  font-weight: 400;
  color: #bdbdbd;
}

.primaryBtn {
  padding: 0.8rem 1.5rem;
  background-color: #206bc4;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
}

.page_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80rem !important;
}

.container .upload_box_container {
  min-width: 110rem;
  margin-top: 5rem;
  background-color: white;
  border: 1px solid rgba(98, 105, 118, 0.2);
  -webkit-box-shadow: 2rem 1rem 3rem -2.5rem rgba(0, 0, 0, 0.1);
          box-shadow: 2rem 1rem 3rem -2.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}

.container .upload_box_container .upload_book_left {
  background-color: white;
  width: 60%;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  padding: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .upload_box_container .upload_book_left h2 {
  font-size: 1.6rem;
  color: #1e293b;
}

.container .upload_box_container .upload_book_left .upload_box {
  margin-top: 5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 2rem;
}

.container .upload_box_container .upload_book_left .upload_box span {
  font-size: 1.4rem;
  color: #1e293b;
}

.container .upload_box_container .upload_book_left .upload_box svg {
  stroke: rgba(30, 41, 59, 0.8);
  cursor: pointer;
}

.container .upload_box_container .upload_book_left .upload_box svg:hover {
  stroke: #1e293b;
}

.container .upload_box_container .upload_book_left .upload_box .uploadedInputFile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.container .upload_box_container .upload_book_left .upload_box .uploadedInputFile svg.uploadedIcon {
  stroke: green;
}

.container .upload_box_container .upload_book_left .upload_box .uploadedInputFile svg.uploadedIcon:hover {
  stroke: green;
  cursor: auto;
}

.container .upload_box_container .upload_book_left .upload_box .uploadedInputFile svg.clearUploadFile {
  stroke: red !important;
  stroke-width: 0.3rem;
  position: absolute;
  top: 0;
  right: 1rem;
}

.container .upload_box_container .upload_book_left .upload_box span.uploadedName {
  color: green;
  text-align: center;
}

.container .upload_box_container .upload_book_left .upload_box .upload_button {
  padding: 0.8rem 5rem;
  font-size: 1.6rem;
  background-color: #206bc4;
  border: #206bc4 thin solid;
  border-radius: 0.4rem;
  height: 4rem;
  width: 15rem;
  color: white;
  cursor: pointer;
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container .upload_box_container .upload_book_left .upload_box .upload_button:hover {
  background-color: #10509b;
  border-color: #10509b;
}

.container .upload_box_container .upload_book_left .upload_box .upload_button:disabled {
  background-color: #cdcdcd;
  border: #cdcdcd thin solid;
  color: #838383;
}

.container .upload_box_container .upload_book_left .upload_box .upload_input {
  display: none;
}

.container .upload_box_container .upload_book_left .upload_box .upload_actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2rem;
  width: 100%;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
}

.container .upload_box_container .upload_book_left .upload_box .upload_actions .upload_select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.container .upload_box_container .upload_book_left .upload_box .upload_actions .upload_select label {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}

.container .upload_box_container .upload_book_left .upload_box .upload_actions .upload_select select {
  font-size: 1.4rem;
  width: 100%;
  padding: 0.5rem;
  border: #dfdfdf thin solid;
  border-radius: 0.4rem;
}

.container .upload_box_container .upload_book_left .upload_box .upload_actions .upload_select select:focus {
  outline: none;
  border: #ccc thin solid;
}

.container .upload_box_container .upload_book_right {
  width: 40%;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  padding: 3rem 1.5rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ededed+0,f9f9f9+9,f9f9f9+100 */
  background: #ededed;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#ededed), color-stop(9%, #f9f9f9), to(#f9f9f9));
  background: linear-gradient(to right, #ededed 0%, #f9f9f9 9%, #f9f9f9 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#f9f9f9',GradientType=1 );
  /* IE6-9 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container .upload_box_container .upload_book_right h2 {
  font-size: 1.6rem;
  color: #1e293b;
}

.container .upload_box_container .upload_book_right .progress_box {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 5rem;
  padding: 1rem;
  padding-top: 3rem;
}
