div[data-amplify-authenticator] {
  background-color: #f5f5f5 !important;
  height: 100% !important;
}

div[data-amplify-router] {
  box-shadow: rgb(30 41 59 / 4%) 0 2px 4px 0 !important;
  border: 1px solid rgb(0 0 0 / 16%) !important;
  background: #fff;
  border-radius: 4px;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out,
    box-shadow 0.3s ease-out;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 30px !important;
}

.amplify-input {
  padding: 10px 10px;
  font-size: 16px;
  border-color: #b0b4b5;
}

.amplify-input:focus,
.amplify-input:active {
  border-color: #b8c2cd !important;
}

.amplify-button.amplify-field__show-password {
  border-color: #b0b4b5;
  font-size: 20px;
}

.amplify-button.amplify-button--primary {
  padding: 10px 10px;
  font-size: 16px;
  background-color: #206bc4 !important;
}

.amplify-button.amplify-button--link {
  font-size: 14px;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 380px !important;
}

.amplify-textfield {
  margin-bottom: 10px !important;
}

.amplify-alert__body {
  font-size: 14px;
}

.amplify-button--loading {
  color: #206bc4;
}
