.rules_container {
  font-size: 1.4rem;
  margin-top: 1.5rem;
}

ol {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
}

ol li {
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.sample_data_container {
  background: #dddddd;
  padding: 0.5rem;
  margin-top: 1.5rem;
}