@import './theme.scss';

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: none;
}

html,
body {
  height: 100%;
  transition: none;
  font-family: $defaultFontFamily;
  font-size: 62.5%;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-y: auto;
  background-color: $whitesmoke;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.score_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date_info {
  color: $grey;
}

.input_date_info {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: $grey;
}